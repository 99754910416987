<template>
  <form action="" class="needs-validation" novalidate @submit="(e)=>{e.preventDefault()}" :class="{'was-validated': showAlert}" ref="form">

    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
      </symbol>
    </svg>

    <div class="alert alert-danger align-items-center" role="alert" :class="{'d-flex': showAlert, 'd-none': !showAlert}">
      <svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="Info:">
        <use xlink:href="#info-fill" />
      </svg>
      <div> {{$t("sabre.booking.form-invalid-message")}}</div>
      <button type="button" class="btn-close" @click="() => {showAlert=false}" aria-label="Close"></button>
    </div>

    <passenger-detail :disabled="bookingStateTag > 0"
      @update="updateBookingInfo"/>

    <ticket-type-selection-form :disabled="bookingStateTag > 1"
      v-if="ticketSelectionMode && bookingStateTag > 0 && !isFCSupplied"
      @update="updateBookingInfo" :initTicket="ticket"/>

    <baggage-passenger-form :disabled="bookingStateTag > 1"
      v-if="bookingStateTag > 0 && !isFCSupplied"
      @update="updateBookingInfo" :sltTicket="ticket"/>

    <seat-selection-form :disabled="bookingStateTag > 1"
      v-if="bookingStateTag > 0 && !isFCSupplied"
      @update="updateBookingInfo"/>

    <payer-detail-form :disabled="bookingStateTag > 2"
      v-if="bookingStateTag > 1"
      @update="updateBookingInfo"/>

    <condition-approval-form :disabled="bookingStateTag > 2"
      v-if="bookingStateTag > 1"
      @update="updateBookingInfo"/>

    <payment-form v-if="bookingStateTag > 2 && bookingStateTag !== 4"/>

    <div class="text-end mt-4" v-if="bookingStateTag < 3">
      <button class="btn btn-warning m-0" type="button" :class="{'disabled': isLoading}" @click="submit">
        <span class="text-light">{{$t('sabre.buttons.continue')}}</span>
        <span class="spinner-border text-light" role="status" aria-hidden="true" v-if="isLoading"></span>
      </button>
    </div>

  </form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'booking-form',
  components: {
    PassengerDetail: () => import('./passengerFormItem/passengerDetail'),
    TicketTypeSelectionForm: () => import('./ticketTypeSelection/ticketTypeSelectionForm'),
    BaggagePassengerForm: () => import('./baggageForm/baggagePassengerForm'),
    SeatSelectionForm: () => import('./seatSelection/seatSelectionForm'),
    PayerDetailForm: () => import('./payerDetail/payerDetailForm'),
    ConditionApprovalForm: () => import('./conditionApproval/conditionApprovalForm'),
    PaymentForm: () => import('./payment/paymentForm'),
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      /**
       * booking tag explain
       * 0: passenger input state
       * 1: additional service state --> no need for fc supplied flight
       * 2: payer input state
       * 3: payment input state
       * 4: Notify card payment completion state
       */
      bookingStateTag: 'GET_FO_BOOKING_STATE',
      routerList: 'GET_FO_BOOKING_ROUTERS',
      isFCSupplied: 'GET_FO_IS_FC_SUPPLIED',
      paymentURL: 'GET_SABRE_PAYMENT_URL',
      entirePassengers: 'GET_SABRE_PASSENGER_DATA',
      orderedFlight: 'GET_SABRE_ORDERED_FLIGHT',
      revalidateInfo: 'GET_SABRE_FO_BOOKING_REVALIDATE_DATA',
      selectedBrandIndex: 'GET_SABRE_FO_BOOKING_TICKET_BRAND_DATA',
      searchQuery: 'GET_SABRE_BOOKING_FLIGHT_SEARCH_QUERY',
      bookingTransId: 'GET_SABRE_BOOKING_TRANS_ID',
      isStaging: 'GET_STAGING_MODE',
    }),
    ticketSelectionMode() {
      const { orderedFlight } = this;
      return orderedFlight?.pricingInformation?.length > 0 && !!orderedFlight.pricingInformation[0].fare.brandFeatures;
    },
    totalSegmentCount() {
      const { entirePassengers, orderedFlight } = this;
      const segmentCount = orderedFlight?.legs?.reduce((total, leg) => total + (leg.segmentExcerpts.length), 0) || 0;
      return (entirePassengers?.length || 0) * segmentCount;
    },
  },
  watch: {
    $router() {
      this.initializeData();
    },
    ticket() {
      const { orderedFlight, isFCSupplied } = this;
      if (isFCSupplied) return;
      this.$store.commit('SET_FO_SABRE_BOOKING_PRICE_INFO', { type: 'brand', price: orderedFlight.pricingInformation[this.ticket]?.fare?.totalFare });
      if (this.bookingStateTag === 1) this.revalidateBrandPrice();
    },
    bookingStateTag() {
      if (this.bookingStateTag === 1) this.revalidateBrandPrice();
      if (this.bookingStateTag === 4) {
        this.notifyCardCompletion();
      }
    },
  },
  data() {
    return {
      showAlert: false,
      passengers: null,
      ticket: -1,
      selectedBaggage: null,
      selectedSeats: null,
      payer: null,
      conditionApproval: false,
    };
  },
  mounted() {
    this.initializeData();
    this.ticket = this.selectedBrandIndex;
    if (this.ticketSelectionMode) {
      this.ticket = 0;
      this.$store.commit('SET_SABRE_FO_BOOKING_TICKET_BRAND_DATA', this.ticket);
    }
    this.revalidateBrandPrice();
  },
  methods: {
    submit() {
      if (this.checkValidation()) {
        this.goNext();
      } else {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
    },
    updateBookingInfo(info) {
      const { type, data } = info;
      switch (type) {
        case 'passenger':
          this.passengers = data;
          break;
        case 'ticketType':
          this.ticket = data;
          this.$store.commit('SET_SABRE_FO_BOOKING_TICKET_BRAND_DATA', this.ticket);
          this.$store.dispatch('STORE_SABRE_SERVICE_DATA');
          break;
        case 'baggage':
          if (!this.ticketSelectionMode) this.ticket = 0;
          this.selectedBaggage = data;
          this.$store.commit('SET_SABRE_FO_BOOKING_BAGGAGE_DATA', this.selectedBaggage);
          this.$store.commit('SET_FO_SABRE_BOOKING_PRICE_INFO', { type: 'baggage', price: this.selectedBaggage.totalPrice });
          this.$store.dispatch('STORE_SABRE_SERVICE_DATA');
          break;
        case 'seat':
          if (!this.ticketSelectionMode) this.ticket = 0;
          this.selectedSeats = data;
          this.$store.dispatch('STORE_SABRE_SERVICE_DATA');
          this.$store.commit('SET_FO_SABRE_BOOKING_PRICE_INFO', { type: 'seat', price: this.selectedSeats?.reduce((total, current) => total + (current?.price || 0), 0) || 0 });
          break;
        case 'payer':
          this.payer = data;
          break;
        case 'condition':
          this.conditionApproval = data;
          break;
        default:
      }
    },
    checkValidation() {
      let checked = true;
      const keys = this.payer ? Object.keys(this.payer) : [];
      switch (this.bookingStateTag) {
        case 0:
          checked = this.passengers.every((passenger) => {
            const keysList = Object.keys(passenger);
            return keysList.every((k) => {
              if (k === 'email') {
                return passenger[k].includes('@') && passenger[k].includes('.');
              } else if (k === 'mobile') {
                return !Number.isNaN(+passenger[k]);
              } else if (k !== 'id' && k !== 'typeIndex' && k !== 'accompaniedByInfant') {
                return !!passenger[k];
              }
              return true;
            });
          });
          break;
        case 1:
          checked = this.ticket !== -1;
          if (!checked) {
            this.showErrorMessage('ticket');
            break;
          }
          // checked = (this.selectedSeats?.length === this.totalSegmentCount);
          // if (!checked) {
          //   this.showErrorMessage('seat');
          // }
          break;
        case 2:
          checked = keys.every((k) => {
            if (k === 'email') {
              return this.payer[k].includes('@') && this.payer[k].includes('.');
            } else if (k === 'mobile') {
              return !Number.isNaN(+this.payer[k]);
            } else if (k === 'id' || k === 'numberOfPayments') {
              return true;
            } else {
              return !!this.payer[k];
            }
          });
          if (!checked) {
            this.showErrorMessage('payer');
            break;
          }
          checked = checked && this.conditionApproval[0];
          if (!checked) {
            this.showErrorMessage('condition');
          }
          break;
        default:
          checked = true;
      }
      return checked;
    },
    async goNext() {
      if (this.bookingStateTag === 0) {
        this.$store.dispatch('STORE_SABRE_PASSENGER_DATA', this.passengers);
      }
      if (this.bookingStateTag === 1) {
        //
      }
      if (this.bookingStateTag === 2) {
        await this.$store.dispatch('FETCH_SABRE_FO_BOOK_PNR', { payer: this.payer, condition: this.conditionApproval });
      }
      if (this.bookingStateTag === 3 && !this.paymentURL) {
        this.doBook();
        return;
      }

      const nextIndex = (this.isFCSupplied && this.bookingStateTag === 0) ? 2 : this.bookingStateTag + 1;

      const stagingParam = this.isStaging ? '/?staging=true' : '';
      this.$router.push(`/sabre/${this.routerList[nextIndex]}${stagingParam}`);
    },
    initializeData() {
      this.$store.dispatch('TAKE_OUT_SABRE_PASSENGER_DATA');
      this.$store.dispatch('TAKE_OUT_SABRE_SERVICES');
      this.$store.dispatch('TAKE_OUT_SABRE_PAYER_CONDITION_INFO');
      this.$store.dispatch('TAKE_OUT_SABRE_PAYMENT_URL_INFO');
    },
    async revalidateBrandPrice() {
      if (this.isFCSupplied) return;
      const stagingParam = this.isStaging ? '&staging=true' : '';

      await this.$store.dispatch('SABRE_FO_REVALIDATE');
      const revalidateTotalFare = this.revalidateInfo.itineraries[0]?.pricingInformation[0]?.fare?.totalFare;
      const validateTotalPrice = revalidateTotalFare?.totalPrice || null;

      if (validateTotalPrice) {
        if (this.orderedFlight.pricingInformation[this.ticket].fare.totalFare.totalPrice !== validateTotalPrice) {
          this.$store.commit('SET_FO_SABRE_BOOKING_PRICE_INFO', { type: 'brand', price: revalidateTotalFare });
          this.showErrorMessage('revalidate-not-same-price');
        }
      } else {
        this.showErrorMessage('revalidate-unavailable-flight');
        this.$store.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', []);
        this.$router.push({ path: `/sabre/fo-search-result/?${this.searchQuery}${stagingParam}` });
      }
    },
    doBook() {
      // this.$store.dispatch('FETCH_SABRE_FO_BOOK_PNR');
    },
    notifyCardCompletion() {
      this.$store.dispatch('FETCH_SABRE_FO_NOTIFY_CARD_COMPLETION');
    },
    showErrorMessage(kind) {
      if (kind === 'payer') { this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'You must input a needful all.'); }
      if (kind === 'ticket') { this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'You did not select one ticket type.'); }
      if (kind === 'seat') { this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'You did not select entire seats.'); }
      if (kind === 'condition') { this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'You must check the first condition.'); }
      if (kind === 'revalidate-not-same-price') { this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'The brand price is not the same. The brand price will be replaced with a new one.'); }
      if (kind === 'revalidate-unavailable-flight') { this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'There is no available flight.'); }
    },
  },
};
</script>

<style scoped>
.btn-close {
  left: 0;
  position: absolute;
  padding: 1rem;
}
</style>
